<template>
    <b-overlay class="h-100" :show="showLoader">
        <div class="h-100" style="margin: 7px;" >
            <b-row style="margin-top: 8px; height: 100vh; max-height: calc(100vh - 125px); padding-bottom: 0;">
                <b-col cols="12"  lg="4" xl="3" style="height: 100%; padding-bottom: 1rem;">
                    <b-card style="box-sizing: border-box; height: 100%; border-radius: 6px;">
                        <div class="d-flex flex-column" style="height: 100%;">

                            <div style="height: 220px;">
                                <div class="d-flex flex-wrap align-items-end">
                                    <v-select style="width: 100%" class=" mb-2" v-model="selected_client" :searchable="true" :filter-by="findClient" label="name" :options="allowedClients" :clearable="false" :reduce="ele => ele.id" @option:selected="clientChanged">
                                        <template #selected-option="{name, disabled}" style="max-width: 100% !important;">
                                            <div :class="{'text-danger': disabled}">
                                                {{ getOptionName(name) }}
                                            </div>
                                        </template>
                                        <template v-slot:option="option">
                                            <div style="color:#595959!important;">{{ getOptionName(option.name) }}</div>
                                            <div class="font-weight-bolder" style="color:#595959!important;">{{getOptionName(option.alias, 20)}}</div>
                                            <div class="d-flex justify-content-end pb-1" v-if="option.disabled">
                                                <b-badge pill variant="danger">{{$t('installation_wizard.user_disabled')}}</b-badge>
                                            </div>
                                        </template>
                                    </v-select>
                                    <div class="mb-2 ml-0 mr-0 w-100 data-border">
                                        <div class="border-text bgCard">
                                            {{$t('installation_wizard.usage')}}
                                        </div>
                                        <div class="pt-1">
                                            <b-row >
                                                <b-col>
                                                    <app-timeline>
                                                        <app-timeline-item
                                                            :title="$t(`installation_wizard.filled`)"
                                                            variant="success">
                                                        </app-timeline-item>
                                                    </app-timeline>
                                                </b-col>
                                                <b-col>
                                                    <app-timeline>
                                                        <app-timeline-item
                                                            :title="$t(`installation_wizard.required`)"
                                                            variant="required">
                                                        </app-timeline-item>
                                                    </app-timeline>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <app-timeline>
                                                        <app-timeline-item
                                                            :title="$t(`installation_wizard.optional`)"
                                                            variant="optional">
                                                        </app-timeline-item>
                                                    </app-timeline>
                                                </b-col>
                                                <b-col>
                                                    <app-timeline>
                                                        <app-timeline-item
                                                            :title="title"
                                                            variant="secondary">
                                                        </app-timeline-item>
                                                    </app-timeline>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="app-timeline-scrollable pretty-scroll flex-grow-1 w-100 h-100 relative " style="overflow-y: auto; padding-top: 5px">
                                <app-timeline style="margin-bottom: 2px;" class=" ml-2" v-for="(route) in routes" :key="route.title">
                                    <div style="margin-top: -2px; display: flex; align-items: center;">
                                        <h5 style="width: 40px">{{route.step}}.</h5>
                                        <app-timeline-item v-if="route.data > 0 && route.accessible && route.hasPermissions"
                                                           @click="changeName(route)"
                                                           :title="`${$t(`menu.subcategory.${route.title}`)}`"
                                                           variant="success"
                                                           style="cursor: pointer">
                                        </app-timeline-item>
                                        <app-timeline-item v-else-if="route.data === 0 && route.accessible && route.required && route.hasPermissions"
                                                           @click="changeName(route)"
                                                           :title="`${$t(`menu.subcategory.${route.title}`)}`"
                                                           variant="required"
                                                           style="cursor: pointer">
                                        </app-timeline-item>
                                        <app-timeline-item v-else-if="route.data === 0 && route.accessible && !route.required && route.hasPermissions"
                                                           @click="changeName(route)"
                                                           :title="`${$t(`menu.subcategory.${route.title}`)}`"
                                                           variant="optional"
                                                           style="cursor: pointer">
                                        </app-timeline-item>
                                        <app-timeline-item v-else
                                                           :tooltip="getTooltip(route.title)"
                                                           @click="changeName(route)"
                                                           :title="`${$t(`menu.subcategory.${route.title}`)}`"
                                                           style="cursor: not-allowed;"
                                                           variant="secondary">
                                        </app-timeline-item>
                                    </div>
                                </app-timeline>
                            </div>

                            <div style="height: 40px;" v-if="$store.getters['setup/setupProgress'] !== 0" class="mt-2">
                                <b-button @click="$router.push('/')" :class="{'fade-in': ($store.getters['setup/setupProgress'] < 0)}" variant="primary" class="float center"
                                          style="width: 94%; margin-right: 6px; position: absolute; left:3%; bottom:2%;">{{$t('general.stop')}}</b-button>
                            </div>
                        </div>

                    </b-card>
                </b-col>

                <b-col cols="12" lg="8" xl="9" style="height: 100%; padding-bottom: 1rem;">
                    <b-card style="box-sizing: border-box; height: 100%; border-radius: 6px;">
                        <logos v-on:load="loadOnChange" v-if="click==='logos'"></logos>
                        <warehouses v-on:load="loadOnChange" v-if="click === 'warehouses'"></warehouses>
                        <business-units v-on:load="loadOnChange" v-if="click === 'business_units'"></business-units>
                        <VAT v-on:load="loadOnChange" v-if="click === 'vat'"></VAT>
                        <ingredient-category v-on:load="loadOnChange" v-if="click === 'ingredient_categories'"></ingredient-category>
                        <ingredient-subcategory  v-on:load="loadOnChange" v-if="click === 'ingredient_sub_categories'"></ingredient-subcategory>
                        <ingredients v-on:load="loadOnChange" v-if="click === 'ingredients'"></ingredients>
                        <product-categories v-on:load="loadOnChange" v-if="click === 'categories'"></product-categories>
                        <product-subcategories v-on:load="loadOnChange" v-if="click === 'subcategories'"></product-subcategories>
                        <products v-on:load="loadOnChange" v-if="click === 'products'"></products>
                        <product-order-options v-on:load="loadOnChange" v-if="click === 'order_options'"></product-order-options>
                        <product-display-groups v-on:load="loadOnChange" v-if="click === 'product_display_groups'"></product-display-groups>
                        <predefined-discounts v-on:load="loadOnChange" v-if="click==='discounts'"></predefined-discounts>
                        <payment-types v-on:load="loadOnChange" v-if="click==='payment_types'"></payment-types>
                        <employee-permission-groups v-on:load="loadOnChange" v-if="click === 'permissions'"></employee-permission-groups>
                        <buyers v-on:load="loadOnChange" v-if="click==='buyers'"></buyers>
                        <suppliers v-on:load="loadOnChange" v-if="click==='suppliers'"></suppliers>
                        <printer-configs v-on:load="loadOnChange" v-if="click==='printers'"></printer-configs>
                        <FURSCertificates v-on:load="loadOnChange" v-if="click==='furs'"></FURSCertificates>


                        <joined-work-premises class="pretty-scroll scrollable-content" style="max-height: 100%;" v-on:load="loadOnChange" v-if="click==='work_premises'"></joined-work-premises>


                        <employees v-on:load="loadOnChange" v-if="click==='employees'"></employees>
                        <payment-deadline class="pretty-scroll scrollable-content" style="max-height: 100%;" v-on:load="loadOnChange" v-if="click==='invoice_config'"></payment-deadline>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>
    import Warehouses from '@/views/Warehouse/Warehouses.vue'
    import BusinessUnits from '@/views/BusinessUnits/BusinessUnits.vue'
    import VAT from '@/views/VAT/VAT.vue'
    import Ingredients from '@/views/Ingredient/Ingredients.vue'
    import IngredientCategory from '@/views/Ingredient/IngredientCategory.vue'
    import IngredientSubcategory from '@/views/Ingredient/subcategory/IngredientSubcategory.vue'
    import ProductCategories from '@/views/ProductCategory/ProductCategories.vue'
    import productSubcategories from '@/views/ProductSubcategory/ProductSubcategories.vue'
    import Products from '@/views/Product/Products.vue'
    import ProductOrderOptions from '@/views/ProductOrderOption/ProductOrderOptions.vue'
    import productDisplayGroups from '@/views/ProductDisplayGroup/ProductDisplayGroups.vue'
    import PredefinedDiscounts from '@/views/PredefinedDiscount/PredefinedDiscounts.vue'
    import PaymentTypes from '@/views/PaymentType/PaymentTypes.vue'
    import EmployeePermissionGroups from '@/views/EmployeePermissionGroup/EmployeePermissionGroups.vue'
    import Buyers from '@/views/Buyer/Buyers.vue'
    import Suppliers from '@/views/Supplier/Suppliers.vue'
    import Logos from '@/views/Logo/Logos.vue'
    import PrinterConfigs from '@/views/PrinterConfig/PrinterConfigs.vue'
    import FURSCertificates from '@/views/FURSCertificate/FURSCertificates.vue'
    import Employees from '@/views/Employee/Employees.vue'
    import PaymentDeadline from '@/views/InvoiceConfiguration/PaymentDeadline.vue'
    import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import {BCard, BRow, BCol, BButton, BBadge, BOverlay} from 'bootstrap-vue'
    import VSelect from 'vue-select'
    import store from '../store'
    import {WizardRequirements, setupProgress} from '@/libs/enums/InstallationWizard'
    import Vue from 'vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import JoinedWorkPremises from '@/views/WorkPremises/JoinedWorkPremises.vue'
    export default {
        components: {
            JoinedWorkPremises,
            BOverlay,
            IngredientSubcategory,
            IngredientCategory,
            AppTimelineItem,
            AppTimeline,
            BBadge,
            BCard,
            BRow,
            BCol,
            BButton,
            Products,
            Warehouses,
            BusinessUnits,
            VAT,
            Ingredients,
            ProductCategories,
            productSubcategories,
            ProductOrderOptions,
            productDisplayGroups,
            PredefinedDiscounts,
            PaymentTypes,
            EmployeePermissionGroups,
            Buyers,
            Suppliers,
            Logos,
            PrinterConfigs,
            FURSCertificates,
            Employees,
            PaymentDeadline,
            VSelect
        },
        data() {
            return {
                windowWidth: 0,
                showLoader: false,
                allowedClients: [],
                selected_client: null,
                setupWizard: {
                },
                click: '',
                routes: [],
                redirect: true
            }
        },
        methods: {
            updateWindowWidth() {
                this.windowWidth = window.innerWidth
            },
            findClient: (option, label, search) => {
                const temp = search.toLowerCase()
                return option.name.toLowerCase().indexOf(temp) > -1 ||
                    option.alias.toLowerCase().indexOf(temp) > -1
            },
            async clientChanged() {
                try {

                    this.showLoader = true
                    this.click = ''
                    localStorage.setItem('clickedItem', '')
                    const response = await this.$http.get(`/api/client/v1/change_client/${this.selected_client}`)
                    await this.$store.dispatch('user/setUserData', response.data)
                    await this.$store.dispatch('setup/setRedirect', true)

                    const res = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
                    const permissions = res.data ?? []

                    const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
                    const clientData = res1.data ?? {}

                    this.$store.dispatch('user/setUserPermissions', permissions)
                    this.$store.dispatch('client/setClientData', clientData)
                    await this.$store.dispatch('setup/setReturnablePackagingEnabled')

                    await this.loadOnChange()


                } catch {
                    this.$printError(this.$t('print.error.installation_wizard.changing_clients'))
                    this.showLoader = false
                    this.selected_client = null
                } finally {
                    if (!this.$store.getters['setup/setupProgress'] === setupProgress.NONE) {
                        if (this.$router.currentRoute.name !== 'installation-wizard') {
                            await this.$router.push('/installation-wizard')
                        }
                    }
                }
            },
            async loadClients() {
                try {
                    const response =  await this.$http.get('/api/client/v1/assigned_clients')
                    this.allowedClients = response.data ?? []
                } catch (err) {
                    this.$printError(this.$t('print.error.installation_wizard.loading_clients'))
                }
            },
            getOptionName(nameIn, len = 17) {
                let name = nameIn.substring(0, len)
                if (name.length === len) {
                    name += '...'
                }

                return name
            },
            getTooltip(name) {
                const fill = this.access.find(el => el.name === name)

                if (!fill) {
                    return ''
                }

                if (!fill.hasPermissions) {
                    return 'Nimate ustreznih pravic'
                }

                let requirements = ''
                for (let i = 0; i < fill.requirements.length; i++) {
                    requirements += `${this.$t(`menu.subcategory.${fill.requirements[i]}`)}`
                    if (i < fill.requirements.length - 1) {
                        requirements += ', '
                    }
                }
                return `${this.$t('general.missing_data')} ${requirements}`
            },
            async changeName(route) {

                if (!route.hasPermissions) {
                    return
                }

                if (route.title === this.click) {
                    return
                }
                if (route.accessible) {
                    this.click = route.title
                }
                localStorage.setItem('clickedItem', this.click)
            },
            async loadOnChange() {
                try {
                    const res = await this.$http.get('/api/client/v1/installation_wizard/')
                    this.setupWizard = res.data ?? {}

                    this.formatSetupArray()
                    await this.$store.dispatch('setup/getSetupProgress')
                    this.showLoader = false
                    if (this.$store.getters['setup/setupProgress'] !== setupProgress.NONE && this.$store.getters['setup/redirect'] === true) {
                        if (this.redirect) {
                            await this.$router.push('/')
                        }
                        this.redirect = true
                    }
                    await this.getClickedItem()
                } catch {
                    this.$printError(this.$t('print.error.installation_wizard.loading_data'))
                    this.showLoader = false
                }
            },
            async getClickedItem() {
                if (localStorage.getItem('clickedItem') !== '') {
                    this.click = localStorage.getItem('clickedItem')
                }

                if (this.routes.length > 0) {
                    if (!this.click || this.click === '') {
                        const index = this.routes.findIndex(x => x.data === 0)
                        if (index !== -1) await this.changeName(this.routes[index])
                    }
                }
            },
            formatSetupArray() {

                this.routes = []
                let i = 1
                for (const prop in this.setupWizard) {
                    if (Object.prototype.hasOwnProperty.call(this.setupWizard, prop)) {
                        if (prop === 'id' || prop === 'client_id') {
                            continue
                        }

                        const hasAccess = this.access.findIndex(ele => ele.name === prop)

                        if (hasAccess === -1) {
                            continue
                        }

                        const payload = {
                            title: prop,
                            data: this.setupWizard[`${prop}`],
                            step: i,
                            hasPermissions: this.access[hasAccess].hasPermissions
                        }
                        i++
                        this.routes.push(payload)
                    }
                }

                for (let i = 0; i < this.routes.length; i++) {

                    const tmp =  this.access.find(el => el.name === this.routes[i].title)

                    if (!tmp) {
                        continue
                    }

                    this.routes[i].required = tmp.required
                    if (tmp.requirements.length > 0) {
                        for (let j = 0; j < tmp.requirements.length; j++) {
                            const obj = this.routes.find(el => el.title === tmp.requirements[j])

                            if (!obj) {
                                continue
                            }

                            if (obj.data > 0) {
                                this.routes[i].accessible = true
                            } else {
                                this.routes[i].accessible = false
                                break
                            }
                        }
                    } else {
                        this.routes[i].accessible = true
                    }
                }
            }
        },
        watch: {
            selected_client(newValue) {
                const client = this.allowedClients.find(x => x.id === newValue)
                this.$store.dispatch('user/setSelectedClient', client)
            }
        },
        async mounted() {
            this.showLoader = true
            if (this.userData) {
                this.selected_client = this.userData.client_id
            }
            if (this.selected_client === null) {
                this.showLoader = false
            }
            await this.loadClients()
            await this.loadOnChange()
            this.showLoader = false


            this.windowWidth = window.innerWidth
            window.addEventListener('resize', this.updateWindowWidth)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateWindowWidth)
        },
        computed: {
            access() {
                const client = this.$store.getters['client/clientData']

                const access = []

                if (client.tier === Tiers.Essential) {
                    access.push(
                        {
                            name: WizardRequirements.businessUnits,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: []
                        }
                    )
                } else {
                    access.push(
                        {
                            name: WizardRequirements.businessUnits,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: [WizardRequirements.warehouses]
                        }
                    )
                }


                access.push(
                    {
                        name: WizardRequirements.vat,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.categories,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.vat]
                    },
                    {
                        name: WizardRequirements.subcategories,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.categories]
                    },
                    {
                        name: WizardRequirements.products,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.categories, WizardRequirements.subcategories]
                    },
                    {
                        name: WizardRequirements.orderOptions,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.products]
                    },
                    {
                        name: WizardRequirements.productDisplayGroups,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.products]
                    },
                    {
                        name: WizardRequirements.discounts,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.paymentTypes,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.buyers,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.logos,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.printers,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.furs,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.permissions,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.employees,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: [WizardRequirements.permissions]
                    },
                    {
                        name: WizardRequirements.invoiceConfig,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    }
                )


                if (client.tier === Tiers.Standard || client.tier === Tiers.Premium) {
                    access.push(
                        {
                            name: WizardRequirements.warehouses,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: []
                        },
                        {
                            name: WizardRequirements.ingredientCategories,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.warehouses, WizardRequirements.vat]
                        },
                        {
                            name: WizardRequirements.ingredientSubCategories,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.ingredientCategories]
                        },
                        {
                            name: WizardRequirements.ingredients,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.ingredientSubCategories]
                        },
                        {
                            name: WizardRequirements.suppliers,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.InventoryManagement),
                            requirements: []
                        },
                        {
                            name: WizardRequirements.workPremises,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: [WizardRequirements.businessUnits]
                        }
                    )
                }

                return access
            },
            title() {
                if ((this.windowWidth >= 410 && this.windowWidth <= 450) || (this.windowWidth >= 1145 && this.windowWidth <= 1200) || (this.windowWidth > 1555 && this.windowWidth < 1710)) {
                    return this.$t('installation_wizard.user_disabled_short')
                } else {
                    return this.$t('installation_wizard.user_disabled')
                }
            },
            store() {
                return store
            },
            userData() {
                return this.$store.getters['user/userData']
            }
        }
    }
</script>
<style scoped>
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fade-in {
  animation: fadeIn 0.8s;
}

.scrollable-content {
  height: 78vh; /* fit 100% of screen (used only for invoiceConfig) */
  overflow-y: auto; /* Enable vertical scrolling */
}
.pretty-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #2d2d2d;
  border-radius: 0.5rem;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }
}

/* Track */
.pretty-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.pretty-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: rgba(241,241,241,.4);
}

.data-border{
  border: 2px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
//background: white;
  padding: 0 15px;
  font-weight: bold;
}

</style>
